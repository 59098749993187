<template>
  <div class="profile-body" v-if="$oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
    ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="edit-con button-reg">
      <b-button pill class="asmt-bt my-btn" size="lg" @click="backToProfile()">
        <span class="primary-color btn-text">Back To My Profile</span>
      </b-button>
    </div>
    <div class="main-con">
      <MyProfile />
    </div>
    <div class="user-search-con">
      <SearchUser :user="userList" @userSelected="userSelected($event)"></SearchUser>
    </div>
    <div class="edit-pr-con">
      <div>
        <div class="recent-users">Profile Details:</div>
      </div>
      <div class="target-card-lable edit-user-imf">
        <div class="img-con">
          <div v-if="myDetails.profilePicUrl">
            <b-img :src="myDetails.profilePicUrl" rounded="circle" alt="" width="55" height="55" class="pro-img-border"
              @error="setAltImg()"></b-img>
          </div>
          <div class="no-img-my" v-else>
            <span class="profile-sh">{{ myDetails.userName | shortName }}</span>
          </div>
        </div>

        <div class="font-sb">
          <h4>{{ myDetails.userName }}</h4>
        </div>
      </div>
      <div class="row user-field-con">
        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="masterData">
          <label for="pillar" class="form-label select-lable lable-text">User Role</label>
          <select class="
              form-control
              select-text select-text-pd
              cust-st-icon
              user-role
            " id="userId" v-model="userId" :disabled="userRoleDropdownUp.length <= 1" @change="getDropdownValue()">

            <option v-for="userRoleDropdown in userRoleDropdownUp" v-bind:key="userRoleDropdown.roleId"
              :value="userRoleDropdown.roleId">
              {{ userRoleDropdown.roleName }}
            </option>
          </select>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="roleName == 'User' ||
          roleName == 'Plant Sub Pillar Leader' ||
          roleName == 'Global Pillar Leader' ||
          roleName == 'Plant Pillar Leader' ||
          roleName == 'Regional Pillar Leader'
          ">
          <label for="pillar" class="form-label select-lable lable-text">Pillar Name<span class="req"
              v-if="checkPillar && regionId != 1">*</span></label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="pillarId">
            <option value="0" :disabled="regionId != 1 && roleName !== 'User'">Select Pillar</option>
            <option v-for="pillarDropdown in masterData.pillarDropdown" :key="pillarDropdown.pillarId"
              :value="pillarDropdown.pillarId">
              {{ pillarDropdown.pillarName }}
            </option>
          </select>

        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="roleName == 'User' ||
          roleName == 'Plant Sub Pillar Leader' ||
          roleName == 'Plant PO Leader' ||
          roleName == 'Plant Pillar Leader' ||
          roleName == 'Plant Manager' ||
          roleName == 'Regional PO Leader' ||
          roleName == 'Regional Pillar Leader'
          ">
          <label for="pillar" class="form-label select-lable lable-text">Region<span class="req">*</span></label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="regionId"
            @change="onchangeRegion()">
            <option value="0" disabled>Select Region</option>
            <option v-for="regionDrop in masterData.regionDropdown" :key="regionDrop.regionId"
              :value="regionDrop.regionId">
              {{ regionDrop.regionName }}
            </option>
          </select>

        </div>

        <!-- Energy Region -->

        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="roleName == 'Energy User'">
          <label for="pillar" class="form-label select-lable lable-text">Energy Region<span class="req">*</span></label>
          <select class="form-control select-text select-text-pd cust-st-icon user-role" id="pillar"
            v-model="energyRegionId" :disabled="$oidc.userProfile.roles.includes(roles.POENERGYUSER)"
            @change="onchangeEnergyRegion($event)">
            <option value="0" disabled>Select Region</option>
            <option v-for="regionDrop in EnergyRegionData" :key="regionDrop.energyRegionId"
              :value="regionDrop.energyRegionId">
              {{ regionDrop.energyRegionName }}
            </option>
          </select>

        </div>
      </div>

      <div class="row user-field-con">
        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="roleName == 'User' ||
          roleName == 'Plant Sub Pillar Leader' ||
          roleName == 'Plant PO Leader' ||
          roleName == 'Plant Pillar Leader' ||
          roleName == 'Plant Manager'
          ">
          <label for="pillar" class="form-label select-lable lable-text">Plant<span class="req"
              v-if="checkPlant && regionId != 1">*</span></label>
          <select class="
              form-control
              select-text select-text-pd
              cust-st-icon
              user-role
            " id="plantId" v-model="plantId" :disabled="!plantDropdown.length">
            <option value="0" :disabled="regionId != 1 && roleName !== 'User'">Select Plant</option>
            <option v-for="plantDropdown in plantDropdown" v-bind:key="plantDropdown.plantId"
              v-bind:value="plantDropdown.plantId">
              {{ plantDropdown.plantName }}, {{ plantDropdown.regionCode }}
            </option>
          </select>

        </div>

        <!-- Energy Plant -->

        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="roleName == 'Energy User'">
          <label for="pillar" class="form-label select-lable lable-text">Energy Plant<span class="req"
              v-if="checkPlant && regionId != 1">*</span></label>
          <select class="form-control select-text select-text-pd cust-st-icon user-role" id="plantId"
            v-model="energyPlantId" :disabled="$oidc.userProfile.roles.includes(roles.POENERGYUSER)"
            @change="onchangeEnergyPlant($event)">
            <option value="0" disabled>Select Plant</option>
            <option v-for="plantDropdown in plantDropdownForEnergy" v-bind:key="plantDropdown.energyPlantId"
              v-bind:value="plantDropdown.energyPlantId">

              {{ plantDropdown.energyPlantName }}
            </option>
          </select>
          <span style="color: red;font-size:14px;" v-if="$oidc.userProfile.roles.includes(roles.POENERGYUSER)">
            Note:Please contact your regional administrator to change plant.
          </span>

        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-top"
          v-if="roleName == 'User' || roleName == 'Plant Sub Pillar Leader'">
          <label for="pillar" class="form-label select-lable lable-text">Plant Area</label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="planId">
            <option value="0">Select Plant Area</option>

            <option v-for="plantAreaDropdown in masterData.plantAreaDropdown" :key="plantAreaDropdown.plantAreaId"
              :value="plantAreaDropdown.plantAreaId">
              {{ plantAreaDropdown.plantAreaName }}
            </option>
          </select>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="roleName == 'Regional PO Leader' ||
          roleName == 'Regional Pillar Leader' ||
          roleName == 'Plant PO Leader' ||
          roleName == 'Plant Pillar Leader'
          ">
          <label for="pillar" class="form-label select-lable lable-text">Business Unit</label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="bussinessUnitId">
            <option value="0">Select Business Unit</option>
            <option v-for="bussinessUnitDropdown in masterData.bussinessUnitDropdown"
              :key="bussinessUnitDropdown.bussinessUnitId" :value="bussinessUnitDropdown.bussinessUnitId">
              {{ bussinessUnitDropdown.bussinessUnitName }}
            </option>
          </select>
        </div>
      </div>

      <div class="row user-field-con">
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text">Email Id</label>
          <b-form-input class="form-control select-text select-text-pd text-class" id="emailId" :value="myDetails.emailId"
            disabled>
          </b-form-input>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text">Country Code</label>
          <b-form-input class="form-control select-text select-text-pd text-class" id="contactNo"
            :value="myDetails.countryCode" disabled>
          </b-form-input>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text">Contact Number</label>
          <b-form-input class="form-control select-text select-text-pd text-class" id="contactNo"
            :value="myDetails.contactNumber" disabled>
          </b-form-input>
        </div>
      </div>
    </div>
    <div class="mat-info-con add-action-footer-info border-top">
      <b-button class="primar-btn discard-btn" size="lg" @click="discardEdit()">
        Discard
      </b-button>
      <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="saveDetails()">
        Save
      </b-button>
    </div>
    <MessagePopup :routToBack="true" :message="errorMsg" :show="showPopup" :headText="headText" />
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Loader from "../../components/common/Loader.vue";
import MyProfile from "./MyProfile.vue";
import SearchUser from "./SearchUser.vue";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import MessagePopup from "@/components/common/MessagePopup.vue";
import Roles from "@/common/constants/roles";
export default {
  name: "EditUser",
  data() {
    return {
      // apiCount: 0,
      showPopup: false,
      headText: "",
      reqRaise: "",
      roles: Roles,
      errorMsg: "",
      plantSelected: false,
      plantDropdown: [],
      plantDropdownForEnergy: [],
      showLoader: false,
      selectedUSer: "",
      userList: [],
      selectedUSerId: "",
      plantId: "",
      pillar: "",
      masterData: {},
      pillars: [],
      plantList: [],
      pillarId: "",
      regionId: "",
      userId: 0,
      bussinessUnitId: "",
      planId: "",
      contactno: "",
      emailid: "",
      address: "",
      myDetails: [],
      email_id: "",
      regionCode: "",
      regionDropdown: [],
      userroleidDrop: [],
      roleDetails: [],
      userRoleDropdowns: [],
      roleplantId: "",
      userRoleDropdownUp: [],
      roleName: "",
      EnergyRegion: [],
      EnergyRegionData: [],
      energyRegionId: "",
      energyPlantId: "",
    };
  },
  components: {
    Breadcrumb,
    Loader,
    MyProfile,
    SearchUser,
    MessagePopup,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 0,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Edit User Profile",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "My Profile / ", primary: true, redirect: "/profile" },
        { name: "Edit User Profile", primary: false },
      ],
    });
  },
  computed: {
    checkPlant() {
      return (this.roleName == "Plant Sub Pillar Leader" || this.roleName == "Plant PO Leader" || this.roleName == "Plant Pillar Leader" || this.roleName == "Plant Manager")
    },
    checkPillar() {
      return (this.roleName == "Global Pillar Leader" || this.roleName == "Plant Sub Pillar Leader" || this.roleName == "Plant Pillar Leader" || this.roleName == "Regional Pillar Leader")
    },
  },
  created() {
    this.getRegionEnergy();
    this.getMasterData();
    this.email_id = this.$route.params.id;
    this.getListOfUsers();
  },
  methods: {
    setAltImg() {
      this.myDetails.profilePicUrl = "";
    },
    userSelected(id) {
      this.getUserIdData(id);
    },
    backToProfile() {
      this.$router.push("/profile");
    },
    getListOfUsers() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.GETLISTOFUSERS, "get").then((data) => {
        this.userList = data.data;
        this.showLoader = false;
      });
    },
    getUserIdData(id) {
      let userArr = [];
      this.userroleidDrop = [];
      this.userRoleDropdownUp = [];

      ApiCalls.httpCallDetails(Api.EDITPROFILEROLE + id, "get").then((data) => {
        this.myDetails = data.data;
        this.getProfileDetails = true;
        this.roleDetails = data.data.roleDetails;
        for (const element of this.roleDetails) {
          this.userroleidDrop.push(element.userRoleId);
        }

        for (const element of this.userRoleDropdowns) {
          for (let el of this.roleDetails) {
            if (
              element.roleId == el.userRoleId
            ) {
              userArr.push(element);
            }
          }
        }
        this.userRoleDropdownUp = userArr;
        this.userId = this.userroleidDrop[0];

        this.getDropdownValue();
      });
    },
    getDropdownValue() {
      for (const element of this.roleDetails) {
        if (this.userId == element.userRoleId) {
          this.roleName = element.userRole;

          this.roleplantId = element.plantId ? element.plantId : 0;
          this.regionId = element.regionId ? element.regionId : 0;

          this.energyRegionId = element.regionId ? element.regionId : 0;
          this.energyPlantId = element.plantId ? element.plantId : 0;

          console.log("energyRegionId", this.energyRegionId)
          console.log("energyPlantId", this.energyPlantId)
          this.getPlantData();
          this.getPlantDataForEnegy();
          this.planId = element.plantAreaId
            ? element.plantAreaId
            : 0;
          this.pillarId = element.pillarId
            ? element.pillarId
            : 0;
          this.bussinessUnitId = element.bussinessUnitId
            ? element.bussinessUnitId
            : 0;
          this.userId = element.userRoleId
            ? element.userRoleId
            : null;
        }
      }
    },

    getPlantData() {
      if (
        this.regionId != null &&
        this.regionId != "" &&
        this.regionId != 0 &&
        this.regionId != undefined
      ) {
        let url = Api.USERPROFILEPLANTDROPDOWN + this.regionId;

        ApiCalls.httpCallDetails(url, "get").then((data) => {
          this.showLoader = false;
          this.plantDropdown = data.data;
        });
        this.plantId = this.roleplantId ? this.roleplantId : 0;
        //   }
      } else {
        this.plantDropdown = [];
        this.showLoader = false;
      }
    },
    onchangeRegion() {
      this.showLoader = true;
      this.plantSelected = true;
      this.getPlantData();
      this.plantId = 0;
    },
    onchangeEnergyRegion(event) {

      this.showLoader = true;
      this.plantSelected = true;
      this.regionId = event.target.value;
      this.energyPlantId = 0;
      this.roleplantId = 0
      this.getPlantDataForEnegy();
    },
    onchangeEnergyPlant(event) {
      this.plantId = event.target.value;
    },

    // Energy Region
    getRegionEnergy() {
      let url = Api.USERPROFILEENERGYREG;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.EnergyRegionData = data.data;

        console.log("GetEnergyRegionList", this.EnergyRegionData)
        this.regionId = this.energyRegionId
        console.log("this.regionId", this.regionId)

      });

    },

    // Energy Plant
    getPlantDataForEnegy() {

      if (this.regionId != null && this.regionId != "" && this.regionId != 0 && this.regionId != undefined) {
        let plantPayload = {
          regionIds: [this.regionId],
          year: new Date().getFullYear(),
        }
        let url = Api.USERPROFILEENERGYPLANT;
        ApiCalls.httpCallDetails(url, "post", plantPayload).then((data) => {
          console.log("data", data.data)
          this.showLoader = false;

          this.plantDropdownForEnergy = data.data;
          console.log("this.roleplantId", this.roleplantId)
          this.energyPlantId = this.roleplantId = null ? 0 : this.roleplantId;
          this.plantId = this.energyPlantId;
        });
      } else {
        this.plantDropdownForEnergy = [];
        this.energyPlantId = this.roleplantId ? this.roleplantId : 0;
        this.showLoader = false;
      }
    },

    getMasterData() {
      let url = Api.GETMASTERDATA;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.masterData = data.data;

        this.regionDropdown = data.data.regionDropdown;
        this.userRoleDropdowns = data.data.userRoleDropdown;
        this.userRoleDropdownUp = data.data.userRoleDropdown;

        this.getUserIdData(this.email_id);
      });
    },
    saveDetails() {
      this.showLoader = true;
      this.showPopup = false;
      if ((
        this.roleName == "Plant Sub Pillar Leader" ||
        this.roleName == "Plant PO Leader" ||
        this.roleName == "Plant Pillar Leader" ||
        this.roleName == "Plant Manager") &&
        this.regionId != "1" &&
        (this.plantId == "" ||
          this.plantId == undefined ||
          this.plantId == null ||
          this.plantId == "0")
      ) {
        this.showLoader = false;
        this.showPopup = true;
        this.errorMsg = "Please select a Plant.";
        this.headText = "";
      } else if (
        (this.roleName == "User" ||
          this.roleName == "Plant Sub Pillar Leader" ||
          this.roleName == "Plant PO Leader" ||
          this.roleName == "Plant Pillar Leader" ||
          this.roleName == "Plant Manager" ||
          this.roleName == "Regional PO Leader" ||
          this.roleName == "Regional Pillar Leader") &&
        (this.regionId == "" ||
          this.regionId == undefined ||
          this.regionId == null ||
          this.regionId == 0)
      ) {
        this.showLoader = false;
        this.showPopup = true;
        this.errorMsg = "Please select a Region.";
        this.headText = "";
      } else if (
        (
          this.roleName == "Plant Sub Pillar Leader" ||
          this.roleName == "Global Pillar Leader" ||
          this.roleName == "Plant Pillar Leader" ||
          this.roleName == "Regional Pillar Leader") &&
        this.regionId != 1 &&
        (this.pillarId == "" ||
          this.pillarId == undefined ||
          this.pillarId == null ||
          this.pillarId == 0)
      ) {
        this.showLoader = false;
        this.showPopup = true;
        this.errorMsg = "Please select a Pillar.";
        this.headText = "";
        this.showLoader = false;
      } else {
        this.showPopup = false;
        let requestPayload = {
          userId:
            this.myDetails.userId || this.myDetails.userId == 0
              ? this.myDetails.userId
              : null,
          roleDetails: [
            {
              roleId: this.userId ? this.userId : null,
              pillarId: this.pillarId
                ? this.pillarId != 0
                  ? this.pillarId
                  : null
                : null,

              regionId: this.regionId ? this.regionId != 0 ? this.regionId : null : null,
              plantId: this.plantId ? this.plantId != 0 ? this.plantId : null : null,

              plantAreaId: this.planId
                ? this.planId != 0
                  ? this.planId
                  : null
                : null,
              buisnessUnitId: this.bussinessUnitId
                ? this.bussinessUnitId != 0
                  ? this.bussinessUnitId
                  : null
                : null,
            },
          ],
        };
        console.log("PutPayload", requestPayload)
        ApiCalls.httpCallDetails(Api.EDITOTHERUSER, "put", requestPayload).then(
          (data) => {
            this.showLoader = false;
            this.$router.go(-1);
          }
        );
      }
    },
    discardEdit() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
input[type=text]:disabled {
  background: #dddddd;
}

.user-role:disabled {
  background-color: rgba(105, 105, 101, 0.1);
}

.req {
  color: red;
}

.no-img-my {
  border-radius: 100% !important;
  border: 1px solid #d6d3d3;
  height: 5rem;
  width: 5rem;
  background: #f4f1de;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-pr-con {
  padding: 2rem;
  background: #fff;
}

.recent-users {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-bottom: 0.5rem;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  width: 9.166666666666666rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.add-action-footer-info {
  background: #fff;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.top-padding {
  padding-top: 1rem;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.padding-left {
  margin-left: 20px;
}

.action-info-block {
  background: #fff;
  display: flex;
}

.normal-text {
  color: #313131;
}

.lable-text {
  z-index: 8;
}

.action-desc1 {
  /* margin-left: 30px; */
  border: #313131 1px solid;
  border-radius: 4px;
  background: #fff;
  padding: 4px;
  color: #313131;
}

.font-sb {
  font-family: "BarlowSb", sans-serif;
  font-size: 1.333rem;
  line-height: 1.333rem;
  vertical-align: middle;
  text-align: center;
  padding-top: 12px;
  padding-left: 10px;
}

.mat-info1 {
  padding-left: 0.8rem;
}

.target-label {
  padding-left: 5px;
  display: flex;
  vertical-align: middle;
}

.text-class {
  /* Background:#E5E5E5; */
  background-color: rgba(105, 105, 101, 0.1);
  /* opacity:0.3; */
  border: 1px solid #313131;
  color: #313131;
}

.h4-class {
  font-family: "BarlowM", sans-serif;
  font-size: 1.333rem;
  line-height: 1.333rem;
}

.action-table th div {
  text-align: center !important;
  vertical-align: middle !important;
  font-weight: 100;
}

.action-table:first-child {
  padding: 2px;
  text-align: center !important;
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.25rem;
  /* font-weight: 200; */
}

.action-table thead th,
.action-table td {
  text-align: center !important;
  vertical-align: middle !important;
}

.action-table tbody tr,
.action-table td {
  font-family: "BarlowR", sans-serif;
  font-size: 1.1666666666666667rem;
  line-height: 1.6666666666666667rem;
  vertical-align: middle;
  text-align: center;
}

.ellipse-text {
  vertical-align: middle;
  font-family: "BarlowB", sans-serif;
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 1.6666666666666667rem;
}

.principle-name-label {
  margin-top: 5px;
  vertical-align: middle;
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
}

.btn-action {
  background-color: #274ba7;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.ellipse {
  border-radius: 100%;
  width: 26px;
  height: 26px;
  background: #687b9b 0% 0% no-repeat padding-box;

  padding: 3px;
}

.main-con {
  background: #fff;
  margin-top: 1em;
  border-radius: 6px;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
}

.plant-dd {
  width: 30%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.mm-menu {
  margin-right: 6rem;
}

.level-con {
  padding-left: 15px;
  padding-right: 15px;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10px;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.modify-date {
  position: absolute;
  top: 167px;
  right: 50px;
}

.dropdown-custome .dropdown-menu {
  min-width: 2rem;
}

.mat-stats-con {
  display: flex;
  padding-left: 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
}

.mat-status-con {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: transparent !important;
  color: #274ba7 !important;
  height: 3.5rem;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.last-ast-date {
  padding-top: 0.5rem;
  color: #5a5a5a;
}

.ast-action {
  display: flex;
  justify-content: center;
}

.take-ast-btn {
  margin-right: 1rem;
}

@media only screen and (max-width: 575px) {
  .mat-stats-con {
    display: block !important;
  }

  .mat-status-con {
    margin-left: 0px !important;
  }

  .col-top {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }

  .modify-date {
    top: 437px !important;
  }
}

.edit-user-imf {
  display: flex;
  vertical-align: middle;
  margin-top: 1rem;
}

.user-field-con {
  margin-top: 2rem;
}

textarea {
  resize: none;
}

.user-search-con {
  background: #fff;
  padding: 2rem;
  border-bottom: 2px solid #e8e8e8;
}

.edit-con {
  position: absolute;
  top: 8rem;
  right: 4.5rem;
}

.profile-sh {
  font-size: 2rem;
  font-family: "BarlowL", sans-serif;
}
</style>